body {
    margin:0px;
    padding:0px;
    background:#000;
    color:#FFBF00;
    font-size:120%;
    text-shadow: rgba(255,162,0,0.9) 0px 0px 10px;
}

a, a:visited {
    color:#00FFFF !important;
}

html,body,
.App,
#root {
    //height:100vh;
    padding: 0px;
    margin:0px;
}

div[class^="index_editor__"] {
    width:calc(100vw - 60px);
    height:calc(100vh - 60px);
    padding:30px;
    margin:0 auto;  
    overflow: auto;   
    font-size:.7em;
    z-index:1;
    position:relative;
    background:transparent !important;
}

div[class^="index_editor__"]::-webkit-scrollbar {
    width: 0.5em;
}

div[class^="index_editor__"]::-webkit-scrollbar-track {
    background:transparent;
}

div[class^="index_editor__"]::-webkit-scrollbar-thumb {
    background:#FFBF00;
}

div[class^="index_mobileInput__"] { 
    position:relative !important;
    top:0 !important;
    left:0 !important;
    margin-bottom: -21px;

    input[type=text] {
        text-indent: -9999px;
        color: rgba(0,0,0,0);
    }

    input {
        background:transparent;
        width:100%;
        border:none;
        text-indent: -9999px;
        display:block;
        overflow: hidden;
        color: rgba(0,0,0,0);
    }
    input:focus {
        outline:none;
        text-indent: -9999px;
    }
} 

span[class^="index_caretAfter__"] {
    height:13px !important;
    margin:0px 0px 0px 0px;
}

.index_editor__2tqRz .index_caret__MYxcn .index_caretAfter__2B8sk {
    height:13px !important;
    margin:1px 0px;
}

span#welcome-message {
    font-size:1.3em;
    display:block;
    border:1px solid #FFBF00;
    padding:15px;
    margin-bottom:20px;

    span {
        display:block;
    }

    span.small {
        font-size:.8em;
    }
}

#crt:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        to bottom,
        rgba(18, 16, 16, 0) 50%,
        rgba(29, 28, 28, 0.25) 50%
    );
    background-size: 100% 5.5px;
    box-shadow: inset 0px 0px 25px rgba(67, 63, 56, 0.5); 
    z-index:1;
}

.scanline {
    z-index:9999;
    width: 100%;
    height: 100px;
    z-index: 8;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 228, 77, 0.7) 10%,
        rgba(0, 0, 0, 0.1) 100%
    );
    opacity: 0.1;
    position: absolute;
    bottom: 100%;
    animation: scanline 10s linear infinite;
}

@keyframes scanline {
    0% {
        bottom: 100%;
    }
    80% {
        bottom: 100%;
    }
    100% {
        bottom: 0%;
    }
}

img, iframe {
    min-widt:auto;
    width:auto;
    max-width:100%;
}